import React, { useState, useEffect, useContext } from "react";
import { Button, Drawer, Menu, MenuProps, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { HeaderLayoutBase } from "./Header";
import { Img } from "components/Img";
import {
  Brush,
  ClipboardCheck,
  Globe,
  LayoutDashboard,
  ListChecks,
  MessageSquareMore,
  Settings,
  ShieldCheck,
  ShieldX,
  Siren,
  UserRound,
  UsersRound,
} from "lucide-react";
import "../../../styles/color.css";
import {
  LeftOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { AuthContext } from "contexts/AuthContext";
import { destroyCookie } from "nookies";

type MenuItem = Required<MenuProps>["items"][number];

const LayoutBaseBusiness = ({ children }: { children: React.ReactNode }) => {
  const developmentMode = process.env.NODE_ENV === "development" ? true : false;
  const defaultDomain = developmentMode
    ? "http://localhost:3000"
    : process.env.REACT_APP_DOMAIN_LOQI;

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const { layoutData, signOutData } = useContext(AuthContext);
  const { user } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 768);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const getSelectedKey = () => {
    if (
      pathname.startsWith("/business/users") ||
      pathname.startsWith("/business/user")
    )
      return "/business/users";
    if (
      pathname.startsWith("/business/groups") ||
      pathname.startsWith("/business/group")
    )
      return "/business/groups";
    if (
      pathname.startsWith("/business/complaints") ||
      pathname.startsWith("/business/complaint")
    )
      return "/business/complaints";
    if (pathname.startsWith("/business/layout")) return "/business/layout";
    if (pathname.startsWith("/business/domain")) return "/business/domain";
    if (
      pathname.startsWith("/business/configurations") ||
      pathname.startsWith("/business/configuration")
    )
      return "/business/configurations";
    if (
      pathname.startsWith("/business/consents") ||
      pathname.startsWith("/business/consent")
    )
      return "/business/consents";
    if (
      pathname.startsWith("/business/terms-privacy") ||
      pathname.startsWith("/business/term")
    )
      return "/business/terms-privacy";
    if (
      pathname.startsWith("/business/requests") ||
      pathname.startsWith("/business/request")
    )
      return "/business/requests";
    if (
      pathname.startsWith("/business/audits") ||
      pathname.startsWith("/business/audit")
    )
      return "/business/audits";
    if (pathname.startsWith("/business/dashboard"))
      return "/business/dashboard";
    if (pathname.startsWith("/business/inbox")) return "/business/inbox";

    return "";
  };

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleOpenMenuDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const handleMenuClickMobile: MenuProps["onClick"] = (e) => {
    navigate(e.key);

    setDrawerVisible(false);
  };

  const updateViewMode = () => {
    setMobileView(window.innerWidth < 768);
  };

  const handleBackToSuperAdmin = () => {
    destroyCookie(undefined, "auth.token", {
      path: "/",
    });

    setTimeout(() => {
      window.location.href = `${defaultDomain}/superAdmin/management/business`;
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("resize", updateViewMode);
    updateViewMode();

    return () => window.removeEventListener("resize", updateViewMode);
  }, []);

  const items: MenuItem[] = [
    {
      key: "/business/dashboard",
      icon: <LayoutDashboard />,
      label: (
        <span
          className={`${
            pathname.startsWith("/business/dashboard") ? "font-bold" : ""
          }`}
        >
          {" "}
          Dashboard
        </span>
      ),
    },
    {
      key: "/business/complaints",
      icon: <Siren />,
      label: (
        <span
          className={`${
            pathname.startsWith("/business/complaint") ? "font-bold" : ""
          }`}
        >
          {" "}
          Complaints
        </span>
      ),
    },
    {
      key: "/business/users",
      icon: <UserRound />,
      label: (
        <span
          className={`${
            pathname.startsWith("/business/user") ? "font-bold" : ""
          }`}
        >
          Users
        </span>
      ),
    },
    {
      key: "/business/groups",
      icon: <UsersRound />,
      label: (
        <span
          className={`${
            pathname.startsWith("/business/group") ? "font-bold" : ""
          }`}
        >
          Groups
        </span>
      ),
    },
    {
      key: `/business/inbox`,
      icon: <MessageSquareMore />,
      label: (
        <span
          className={`${
            pathname.startsWith("/business/inbox") ? "font-bold" : ""
          }`}
        >
          Inbox
        </span>
      ),
    },

    ...(user && (user.type === "admin" || user.type === "superAdmin")
      ? [
          {
            key: "/business/layout",
            icon: <Brush />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/layout") ? "font-bold" : ""
                }`}
              >
                Layout
              </span>
            ),
          },
          {
            key: "/business/domain",
            icon: <Globe />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/domain") ? "font-bold" : ""
                }`}
              >
                Domain
              </span>
            ),
          },
          {
            key: "/business/configurations",
            icon: <Settings />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/configuration")
                    ? "font-bold"
                    : ""
                }`}
              >
                Configurations
              </span>
            ),
          },
          {
            key: "/business/consents",
            icon: <ClipboardCheck />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/consents") ? "font-bold" : ""
                }`}
              >
                Complaints Consents
              </span>
            ),
          },
          {
            key: "/business/terms-privacy",
            icon: <ShieldCheck />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/terms-privacy")
                    ? "font-bold"
                    : ""
                }`}
              >
                Terms and Privacy
              </span>
            ),
          },
          {
            key: "/business/requests",
            icon: <ShieldX />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/request") ? "font-bold" : ""
                }`}
              >
                Deletion Request
              </span>
            ),
          },
          {
            key: "/business/audits",
            icon: <ListChecks />,
            label: (
              <span
                className={`${
                  pathname.startsWith("/business/audit") ? "font-bold" : ""
                }`}
              >
                Audits
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="w-full flex min-h-screen overflow-x-hidden">
      {!mobileView ? (
        <div
          className={`relative flex flex-col items-center border-r border-[#EAEEF4] transition-all duration-300 ${
            collapsed ? "w-[85px]" : "w-[240px]"
          }`}
        >
          <Button
            className="absolute top-[10px] right-[-40px] z-10 rounded-l-none"
            style={{ background: "#f1f1f1", color: "#bdbdbd" }}
            size="large"
            onClick={handleToggleCollapse}
            icon={!collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          />

          <div className="w-full flex justify-center">
            <Img
              src={layoutData ? layoutData.logo : "/images/new_logo_2.png"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/business/dashboard")}
              className={`my-8 transition-all duration-300 ${
                collapsed ? "w-[80px]" : "w-[120px]"
              }`} // Ajuste a largura conforme necessário
              alt="Logo"
            />
          </div>

          {user && user.type === "superAdmin" && (
            <div className="w-full px-1">
              <Button
                className={`flex gap-2 items-center mb-5 rounded-lg h-[40px] w-full ${
                  collapsed && "justify-center"
                }`}
                style={{ background: "#f1f1f1", color: "#bdbdbd" }}
                onClick={handleBackToSuperAdmin}
              >
                <LeftOutlined />
                {!collapsed && <p>Back to SuperAdmin</p>}
              </Button>
            </div>
          )}

          <Menu
            selectedKeys={[getSelectedKey()]}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items}
            onClick={handleMenuClick}
          />
        </div>
      ) : (
        <div className="relative">
          <Button
            className="absolute top-[10px] right-[-40px] z-10 rounded-l-none"
            style={{ background: "#f1f1f1", color: "#bdbdbd" }}
            size="large"
            onClick={handleOpenMenuDrawer}
            icon={
              !drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />
            }
          />
          <Drawer
            title="Menu"
            placement="left"
            width={250}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            <div className="w-full flex justify-center">
              <Img
                src={layoutData ? layoutData.logo : "/images/new_logo_2.png"}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/business/dashboard")}
                className={`transition-all duration-300 w-[120px]`} // Ajuste a largura conforme necessário
                alt="Logo"
              />
            </div>

            {user && user.type === "superAdmin" && (
              <div className="w-full px-1">
                <Button
                  className="w-full flex gap-2 items-center mt-7 mb-5 rounded-lg h-[40px]"
                  style={{ background: "#f1f1f1", color: "#bdbdbd" }}
                  onClick={handleBackToSuperAdmin}
                >
                  <LeftOutlined />
                  <p>Back to SuperAdmin</p>
                </Button>
              </div>
            )}

            <Menu
              selectedKeys={[getSelectedKey()]}
              mode="inline"
              theme="light"
              items={items}
              onClick={handleMenuClickMobile}
            />
          </Drawer>
        </div>
      )}

      <div className="flex flex-1 flex-col overflow-auto">
        <HeaderLayoutBase />
        <div className="flex-1 md:p-7 p-3 overflow-auto">{children}</div>
      </div>
    </div>
  );
};

export default LayoutBaseBusiness;
