import { Form, Input, notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { putUserByUuid } from "services/userService";
import { PrimaryButton } from "components/PrimaryButton";

export const AdminPersonalInformationsProfileForm = () => {

    const [form] = Form.useForm();
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState<boolean>(false);

    const handleEditInformations = async () => {
        if (!user) {
            throw new Error("UserInfo are required");
        }

        try {
            setLoading(true);
            const name = form.getFieldValue('name');
            const email = form.getFieldValue('email');

            const userData = {
                name,
                email,
            };

            const response = await putUserByUuid(user.uuid as string, userData);
            if (response) {
    
                notification.success({
                    placement: "bottomRight",
                    message: "Informations edited successfully!",
                });
            }
        } catch (error) {
            notification.error({
                placement: "bottomRight",
                message: "Error on edit information.",
            })

        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        form.setFieldsValue({
            name: user?.name,
            email: user?.email
        })
    }, [user])

    return (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            className="w-full flex flex-col gap-5 items-end"
            onFinish={handleEditInformations}
        >
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">
                <Form.Item
                    name="name"
                    label={<span className="font-bold">Name:</span>}
                    rules={[
                        {required: true, message: "This field is required."}
                    ]}
                >
                    <Input
                        id='name'
                        placeholder="Name"
                        className="rounded-md"
                        size="large"
                        allowClear
                    />
                </Form.Item>

                <Form.Item
                    name="email"
                    label={<span className="font-bold">Email:</span>}
                    rules={[
                        {required: true, message: "This field is required."},
                        {type: "email", message: "Input a valid E-mail!"}
                    ]}
                >
                    <Input
                        id='email'
                        placeholder="Email"
                        className="rounded-md"
                        size="large"
                        allowClear
                        type="email"
                    />
                </Form.Item>
            </div>

            <PrimaryButton
                className="flex items-center justify-center"
                htmlType="submit"
                disabled={loading}
                loading={loading}
            >
                Edit Information
            </PrimaryButton>
        </Form>
    )
}