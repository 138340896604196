import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Drawer,
  Menu,
  MenuProps,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { HeaderLayoutBase } from "./Header";
import { Img } from "components/Img";
import { LayoutDashboard, Mail, MessageSquareMore, Siren, Unplug } from "lucide-react";
import '../../styles/color.css';
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { AuthContext } from "contexts/AuthContext";

type MenuItem = Required<MenuProps>['items'][number];

const LayoutBaseAdmin = ({ children }: { children: React.ReactNode }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const [collapsed, setCollapsed] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 768);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const { layoutData } = useContext(AuthContext);


  const getSelectedKey = () => {
    if (pathname.startsWith("/analyst/dashboard"))
      return "/analyst/dashboard";
    if (pathname.startsWith("/analyst/complaints") || pathname.startsWith("/analyst/complaint"))
      return "/analyst/complaints";
    if (pathname.startsWith("/analyst/inbox"))
      return "/analyst/inbox";
    // if (pathname.startsWith("/analyst/chat"))
    // return "/analyst/chat";
    // if (pathname.startsWith("/admin/integrations") || pathname.startsWith("/admin/integration"))
    //   return "/admin/integrations";

    return "";
  };

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleOpenMenuDrawer = () => {
    setDrawerVisible(!drawerVisible);
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {

    navigate(e.key);
  };

  const handleMenuClickMobile: MenuProps['onClick'] = (e) => {
    navigate(e.key);

    setDrawerVisible(false);
  };

  const updateViewMode = () => {
    setMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateViewMode);
    updateViewMode();

    return () => window.removeEventListener("resize", updateViewMode);
  }, []);

  const items: MenuItem[] = [
    {
      key: '/analyst/dashboard',
      icon: <LayoutDashboard />,
      label: <span className={`${pathname.startsWith('/analyst/dashboard') ? 'font-bold' : ''}`}>Dashboard</span>
    },
    {
      key: '/analyst/complaints',
      icon: <Siren />,
      label: <span className={`${pathname.startsWith('/analyst/complaint') ? 'font-bold' : ''}`}>Complaints</span>
    },
    {
      key: `/analyst/inbox`,
      icon: <MessageSquareMore />,
      label: <span className={`${pathname.startsWith('/analyst/inbox') ? 'font-bold' : ''}`}>Inbox</span>
    },
    // {
    //   key: `/analyst/chat`,
    //   icon: <MessageSquareMore />,
    //   label: <span className={`${pathname.startsWith('/analyst/chat') ? 'font-bold' : ''}`}>Chat</span>
    // },
    // {
    //   key: '/admin/integrations',
    //   icon: <Unplug />,
    //   label: <span className={`${pathname.startsWith('/admin/integration') ? 'font-bold' : ''}`}> Integrations</span>
    // },
  ];


  return (
    <div className="w-full flex min-h-screen overflow-x-hidden">
      {!mobileView ? (
        <div className={`relative flex flex-col items-center border-r border-[#EAEEF4] transition-all duration-300 ${collapsed ? "w-[85px]" : "w-[220px]"}`}>
          <Button
            className="absolute top-[10px] right-[-40px] z-10 rounded-l-none"
            style={{ background: "#f1f1f1", color: "#bdbdbd" }}
            size="large"
            onClick={handleToggleCollapse}
            icon={!collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          />
          <div className="w-full flex justify-center">
            <Img
              src={layoutData ? layoutData.logo : "/images/new_logo_2.png"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/analyst/dashboard")}
              className={` my-8 transition-all duration-300 ${collapsed ? "w-[80px]" : "w-[120px]"}`} // Ajuste a largura conforme necessário
              alt="Logo"
            />
          </div>
          <Menu
            selectedKeys={[getSelectedKey()]}
            mode="inline"
            theme="light"
            inlineCollapsed={collapsed}
            items={items}
            onClick={handleMenuClick}
          />
        </div>
      ) : (
        <div className="relative">
          <Button
            className="absolute top-[10px] right-[-40px] z-10 rounded-l-none"
            style={{ background: "#f1f1f1", color: "#bdbdbd" }}
            size="large"
            onClick={handleOpenMenuDrawer}
            icon={!drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          />
          <Drawer
            title="Menu"
            placement="left"
            width={250}
            onClose={() => setDrawerVisible(false)}
            open={drawerVisible}
          >
            <Menu
              selectedKeys={[getSelectedKey()]}
              mode="inline"
              theme="light"
              items={items}
              onClick={handleMenuClickMobile}
            />
          </Drawer>
        </div>
      )}

      <div className="flex flex-1 flex-col overflow-auto">
        <HeaderLayoutBase />
        <div className="flex-1 md:p-7 p-3 overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutBaseAdmin;
