import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import NotFound from "pages/NotFound";
import OpenInvestigationPage from "pages/admin/reports/OpenInvestigation";
import Profile from "pages/admin/Profile";
import { AuthContext } from "contexts/AuthContext";
import { Spin } from "antd";
import { NotificationProvider } from "contexts/NotificationContext";


// Authentication Pages
const Login = React.lazy(() => import("pages/authentication/Login"));
const RecoverPassword = React.lazy(() => import("pages/authentication/RecoverPassword"));
const ResetPassword = React.lazy(() => import("pages/authentication/ResetPassword"));


// Admin Pages 
const ReportsListAdmin = React.lazy(() => import("pages/admin/reports/ReportsList"));
const ReportDetailsAdmin = React.lazy(() => import("pages/admin/reports/ReportDetails/index"));
const ReportIssueDetailsAdmin = React.lazy(() => import("pages/admin/reports/ReportIssueDetails"));
const TaskDetailsAdmin = React.lazy(() => import("pages/admin/reports/TaskDetails"));
const ClosureformAdmin = React.lazy(() => import("pages/admin/reports/Closureform"));
const ChatAdmin = React.lazy(() => import("pages/admin/chat"));
const DashboardAdmin = React.lazy(() => import("pages/admin/Dashboard/index"));

// Whistleblower pages
const AnonymousReportWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/AnonymousReport"));
const ReportStatusWhistleBlower = React.lazy(() => import("pages/whistleblower/reports/ReportStatus/index"));
const CreateReportWhistleBlower = React.lazy(() => import("pages/whistleblower/reports/CreateReport"));
const ChatWhistleBlower = React.lazy(() => import("pages/whistleblower/chat"));
const FirstAccessWhistleBlower = React.lazy(() => import("pages/whistleblower/autentications/FirstAccess"))

// SuperAdmin pages
const DashboardSuperAdmin = React.lazy(() => import("pages/superAdmin/dashboard"));
const BusinessListSuperAdmin = React.lazy(() => import("pages/superAdmin/users/businessList"));
const BusinessCreateEditSuperAdmin = React.lazy(() => import("pages/superAdmin/users/businessCreateEdit"));
const UsersListSuperAdmin = React.lazy(() => import("pages/superAdmin/users/usersList"));
const UsersCreateEditSuperAdmin = React.lazy(() => import("pages/superAdmin/users/userCreateEdit"));
const IntegrationsSuperAdmin = React.lazy(() => import("pages/superAdmin/integrations"));
const OpenAiSuperAdmin = React.lazy(() => import("pages/superAdmin/integrations/openAI/openAISettings"));
const GoogleCloudVisionSuperAdmin = React.lazy(() => import("pages/superAdmin/integrations/googleCloudVision/index"));
const PusherNotificationsSuperAdmin = React.lazy(() => import("pages/superAdmin/integrations/secretPusherNotification/index"));
const LogsSuperAdmin = React.lazy(() => import("pages/superAdmin/logs/logsList"));
const LogDetailsSuperAdmin = React.lazy(() => import("pages/superAdmin/logs/logView"));
const AuditoriumsSuperAdmin = React.lazy(() => import("pages/superAdmin/auditoriums/auditoriumList"));
const AuditoriumDetailsSuperAdmin = React.lazy(() => import("pages/superAdmin/auditoriums/auditView"));
const NotificationsSuperAdmin = React.lazy(() => import("pages/superAdmin/notifications/notificationsList"));
const ConfigurationsSuperAdminPage = React.lazy(() => import("pages/superAdmin/configs"));
const ProfileSuperAdmin = React.lazy(() => import("pages/superAdmin/profile/index"));
const TermsAndPrivacySuperAdmin = React.lazy(() => import("pages/superAdmin/termsAndPrivacy/index"));
const PromptSuperAdmin = React.lazy(() => import("pages/superAdmin/prompts"));

// Business pages
const UsersListBusiness = React.lazy(() => import("pages/business/users/usersList"));
const UserCreateEditBusiness = React.lazy(() => import("pages/business/users/userCreateEdit"));
const SlugsListBusiness = React.lazy(() => import("pages/business/slug/slugsList"));
const AuditoriumsBusiness = React.lazy(() => import("pages/business/auditoriums/auditoriumList"));
const AuditoriumDetailsBusiness = React.lazy(() => import("pages/business/auditoriums/auditView"));
const ProfileBusiness = React.lazy(() => import("pages/business/profile/index"));
const TermsAndPrivacyBusiness = React.lazy(() => import("pages/business/termsAndPrivacy/index"));
const RequestsBusiness = React.lazy(() => import("pages/business/deletionRequest/index"));
const ReportsBusiness = React.lazy(() => import("pages/business/reports/reportsList"));
const ReportEditBusiness = React.lazy(() => import("pages/business/reports/reportEdit"));
const LayoutBusiness = React.lazy(() => import("pages/business/layout/index"));
const ConfigurationsBusiness = React.lazy(() => import("pages/business/configs/index"));
const DashboardBusiness = React.lazy(() => import("pages/business/dashboard/index"));
const GroupsBusiness = React.lazy(() => import("pages/business/groups/groupsList"));
const ChatBusiness = React.lazy(() => import("pages/business/chat"))
const ConsentsBusiness = React.lazy(() => import("pages/business/reportConsents"));

const DocumentReportPage = React.lazy(() => import("pages/admin/reports/ReportDetails/Document/index"));

const TermsPage = React.lazy(() => import("pages/terms"));
const PrivacyPage = React.lazy(() => import("pages/privacy"));

const ProjectRoutes = () => {
  const { user, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <Spin fullscreen spinning={loading} tip="Loading..." />
    );
  }

  return (
    <React.Suspense fallback={<Spin fullscreen spinning={loading} tip="Loading..." />}>
      <Router>
        <NotificationProvider>
          <Routes>

            {/* Default pages */}
            <Route path="/" element={<Login />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />

            {user && user.type === "analyst" && (
              <>
                <Route path="/analyst/complaints" element={<ReportsListAdmin />} />
                <Route path="/analyst/complaint/details" element={<ReportDetailsAdmin />} />
                <Route path="/analyst/complaints/details/openinvestigation" element={<OpenInvestigationPage />} />
                <Route path="/analyst/complaint/issue" element={<ReportIssueDetailsAdmin />} />
                <Route path="/analyst/complaint/task/details" element={<TaskDetailsAdmin />} />
                <Route path="/analyst/complaint/closureform" element={<ClosureformAdmin />} />
                <Route path="/analyst/dashboard" element={<DashboardAdmin />} />
                <Route path="/analyst/profile" element={<Profile />} />
                <Route path="/analyst/inbox" element={<ChatAdmin />} />
                {/* <Route path="/analyst/chat" element={<ChatAdmin />} /> */}
                <Route path="/analyst/complaint/details/document" element={<DocumentReportPage />} />
              </>
            )}

            <Route path="/whistleblower" element={<FirstAccessWhistleBlower />} />
            <Route path="/whistleblower/anonymouscomplaint" element={<AnonymousReportWhistleBlower />} />
            <Route path="/whistleblower/createcomplaint" element={<CreateReportWhistleBlower />} />

            <Route path="/whistleblower/complaintDetails" element={<ReportStatusWhistleBlower />} />
            <Route path="/whistleblower/inbox" element={<ChatWhistleBlower />} />


            {user && user.type === 'superAdmin' && (
              <>
                {/* <Route path="/superAdmin" element={<LoginSuperAdmin />} /> */}
                <Route path="/superAdmin/dashboard" element={<DashboardSuperAdmin />} />

                <Route path="/superAdmin/management/business" element={<BusinessListSuperAdmin />} />
                <Route path="/superAdmin/management/businessData" element={<BusinessCreateEditSuperAdmin />} />
                <Route path="/superAdmin/management/users" element={<UsersListSuperAdmin />} />
                <Route path="/superAdmin/management/user" element={<UsersCreateEditSuperAdmin />} />

                <Route path="/superAdmin/integrations" element={<IntegrationsSuperAdmin />} />
                <Route path="/superAdmin/integrations/openai" element={<OpenAiSuperAdmin />} />
                <Route path="/superAdmin/integrations/google-cloud-vision" element={<GoogleCloudVisionSuperAdmin />} />
                <Route path="/superAdmin/integrations/secret-pusher-notification" element={<PusherNotificationsSuperAdmin />} />

                <Route path="/superAdmin/logs" element={<LogsSuperAdmin />} />
                <Route path="/superAdmin/log" element={<LogDetailsSuperAdmin />} />

                <Route path="/superAdmin/audits" element={<AuditoriumsSuperAdmin />} />
                <Route path="/superAdmin/audit" element={<AuditoriumDetailsSuperAdmin />} />

                <Route path="/superAdmin/notifications" element={<NotificationsSuperAdmin />} />
                <Route path="/superAdmin/configurations" element={<ConfigurationsSuperAdminPage />} />
                <Route path="/superAdmin/profile" element={<ProfileSuperAdmin />} />
                <Route path="/superAdmin/terms-privacy" element={<TermsAndPrivacySuperAdmin />} />
                <Route path="/superAdmin/prompts" element={<PromptSuperAdmin />} />
              </>
            )}

            {user && (user.type === 'admin' || user.type === 'superAdmin') && (
              <>
                <Route path="/business/inbox" element={<ChatBusiness />} />
                <Route path="/business/users" element={<UsersListBusiness />} />
                <Route path="/business/user" element={<UserCreateEditBusiness />} />
                <Route path="/business/profile" element={<ProfileBusiness />} />
                <Route path="/business/complaints" element={<ReportsBusiness />} />
                <Route path="/business/complaint" element={<ReportEditBusiness />} />
                <Route path="/business/dashboard" element={<DashboardBusiness />} />
                <Route path="/business/groups" element={<GroupsBusiness />} />
                <Route path="/business/layout" element={<LayoutBusiness />} />
                <Route path="/business/configurations" element={<ConfigurationsBusiness />} />
                <Route path="/business/domain" element={<SlugsListBusiness />} />
                <Route path="/business/audits" element={<AuditoriumsBusiness />} />
                <Route path="/business/audit" element={<AuditoriumDetailsBusiness />} />
                <Route path="/business/terms-privacy" element={<TermsAndPrivacyBusiness />} />
                <Route path="/business/requests" element={<RequestsBusiness />} />
                <Route path="/business/consents" element={<ConsentsBusiness />} />
              </>
            )}

            {user && user.type === 'supervisor' && (
              <>
                <Route path="/business/inbox" element={<ChatBusiness />} />
                <Route path="/business/users" element={<UsersListBusiness />} />
                <Route path="/business/user" element={<UserCreateEditBusiness />} />
                <Route path="/business/profile" element={<ProfileBusiness />} />
                <Route path="/business/dashboard" element={<DashboardBusiness />} />
                <Route path="/business/groups" element={<GroupsBusiness />} />

                <Route path="/business/complaints" element={<ReportsListAdmin />} />
                <Route path="/business/complaint/details" element={<ReportDetailsAdmin />} />
                <Route path="/business/complaints/details/openinvestigation" element={<OpenInvestigationPage />} />
                <Route path="/business/complaint/issue" element={<ReportIssueDetailsAdmin />} />
                <Route path="/business/complaint/task/details" element={<TaskDetailsAdmin />} />
                <Route path="/business/complaint/closureform" element={<ClosureformAdmin />} />

                <Route path="/business/complaint/details/document" element={<DocumentReportPage />} />
              </>
            )}

            {!loading && <Route path="*" element={<NotFound />} />}

            {!user && <Route path="*" element={<Navigate to="/" />} />}
          </Routes>
        </NotificationProvider>
      </Router>
    </React.Suspense >
  );
};
export default ProjectRoutes;
