import axios from "axios";
import { apiRequest } from "./api";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_BASE_URL;

// export async function createReport(reportData: CreateReportDto) {
//   const response = await apiRequest<any>("post", "/reports/create", reportData);
//   return response;
// }

export async function createReportTemporaryToken(
  token: string,
  reportData: CreateReportDto
) {
  const response = await axios.post(`${apiUrl}/reports/create`, reportData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function validateReportTemporaryToken(
  token: string,
  uuid: string
) {
  const response = await axios.put(
    `${apiUrl}/reports/validate-report?uuid=${uuid}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
}

export async function postDeleteReportRequest(
  reportData: DeleteReportRequestDto
) {
  const response = await apiRequest<any>(
    "post",
    "/reports/delete-request",
    reportData
  );
  return response;
}

export async function postDeleteReportRequestReportToken(
  token: string,
  reportData: DeleteReportRequestDto
) {
  const response = await axios.post(
    `${apiUrl}/reports/delete-request`,
    reportData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
}

export async function postClosureReportRequest(data: ClosureReportRequestDto) {
  const response = await apiRequest<any>(
    "post",
    `/reports/closure-request`,
    data
  );
  return response;
}

export async function generateReport(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/generate?uuid=${uuid}`
  );
  return response;
}

export async function generateReportTemporaryToken(
  token: string,
  uuid: string
) {
  const response = await axios.get(`${apiUrl}/reports/generate?uuid=${uuid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export async function getReportByUuid(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-uuid?uuid=${uuid}`
  );
  return response;
}

export async function getReportByCode(code: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-code?code=${code}`
  );
  return response;
}

export async function getReportByCodeReportToken(token: string, code: string) {
  const response = await axios.get(
    `${apiUrl}/reports/find-by-code?code=${code}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
}

export async function selectReports() {
  const response = await apiRequest<any>("get", `/reports/select`);
  return response;
}

export async function getReportByCodeAdmin(code: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/find-by-code-admin?code=${code}`
  );
  return response;
}

export async function getUsersByReportUuid(reportUuid: string) {
  const response = await apiRequest<ReadUsersDto[]>(
    "get",
    `/reports/users-by-reportUuid?reportUuid=${reportUuid}`
  );
  return response;
}

export async function getReportsListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string,
  caseIssues?: string[],
  issuePriorities?: string[],
  statuses?: string[],
  initDate?: string,
  endDate?: string
) {
  let query = "?";
  let caseIssuesSearch: string[] = [];
  let issuePrioritiesSearch: string[] = [];
  let statusSearch: string[] = [];

  if (caseIssues && caseIssues.length) {
    caseIssuesSearch = caseIssues.map(
      (caseIssue) => `caseIssues=${caseIssue}&`
    );
  }

  if (issuePriorities && issuePriorities.length) {
    issuePrioritiesSearch = issuePriorities.map(
      (issuePriority) => `issuePriorities=${issuePriority}&`
    );
  }

  if (statuses && statuses.length) {
    statusSearch = statuses.map((status) => `status=${status}&`);
  }

  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}&` : "";

  if (caseIssuesSearch.length) {
    query += caseIssuesSearch.join("");
  }

  if (issuePrioritiesSearch.length) {
    query += issuePrioritiesSearch.join("");
  }

  if (statusSearch.length) {
    query += statusSearch.join("");
  }

  query += initDate ? `initDate=${dayjs(initDate).toISOString()}&` : "";
  query += endDate ? `endDate=${dayjs(endDate).toISOString()}` : "";

  const response = await apiRequest<any>("get", `/reports/list${query}`);
  return response;
}

export async function getDeleteRequestListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListDeleteReportRequestDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListDeleteReportRequestDto>(
    "get",
    `/reports/list-delete-requests${query}`
  );
}

export async function getClosureRequestListAndSearch(
  page: number,
  itemsPerPage: number,
  search?: string
): Promise<ListClosureReportRequestDto> {
  let query = "?";
  query += page ? `page=${page}&` : "";
  query += itemsPerPage ? `itemsPerPage=${itemsPerPage}&` : "";
  query += search ? `search=${search}` : "";

  return await apiRequest<ListClosureReportRequestDto>(
    "get",
    `/reports/list-closure-requests${query}`
  );
}

export async function generatePromptTest(
  promptUuid: string,
  subject: string,
  text: string
): Promise<GenerateTestPromptDto> {
  let query = "?";
  query += promptUuid ? `promptUuid=${promptUuid}&` : "";
  query += subject ? `subject=${subject}&` : "";
  query += text ? `text=${text}` : "";

  return await apiRequest<GenerateTestPromptDto>(
    "get",
    `/reports/generate-test${query}`
  );
}

export async function updateReport(uuid: string, reportData: UpdateReportDto) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update?uuid=${uuid}`,
    reportData
  );
  return response;
}

export async function updateReportTemporaryToken(
  token: string,
  uuid: string,
  reportData: UpdateReportDto
) {
  const response = await axios.put(
    `${apiUrl}/reports/update?uuid=${uuid}`,
    reportData,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
}

export async function updateDeleteRequestReport(
  uuid: string,
  reportData: UpdateReportDeleteRequestDto
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-delete-request?uuid=${uuid}`,
    reportData
  );
  return response;
}

export async function updateClosureRequestReport(
  uuid: string,
  reportData: UpdateReportClosureRequestDto
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-closure-request?uuid=${uuid}`,
    reportData
  );
  return response;
}

export async function updateReportWasRead(uuid: string) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-wasRead?uuid=${uuid}`
  );
  return response;
}

export async function assignUsersReport(
  uuid: string,
  data: AssignUsersReportDto
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-add-users?uuid=${uuid}`,
    data
  );
  return response;
}

export async function updateReportStatus(
  reportUuid: string,
  reportStatusUUID: string
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-reportStatus?uuid=${reportUuid}`,
    { reportStatusUUID }
  );
  return response;
}

export async function updateReportIssuePriority(
  reportUuid: string,
  reportIssuePriorityUUID: string
) {
  const response = await apiRequest<any>(
    "put",
    `/reports/update-reportIssuePriority?uuid=${reportUuid}`,
    { reportIssuePriorityUUID }
  );
  return response;
}

export async function generateReportIssuesByReportUuid(reportUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/reports/generate?uuid=${reportUuid}`
  );
  return response;
}

export async function deleteReport(uuid: string) {
  const response = await apiRequest<any>(
    "delete",
    `/reports/soft-delete?uuid=${uuid}`
  );
  return response;
}
