import React, { createContext, ReactNode, useEffect, useState } from "react";

import { parseCookies, destroyCookie, setCookie } from "nookies";

import { login, verifyToken } from "../services/auth";
import queryString from "query-string";
import { getLayoutByDomain } from "services/layouts.service";
import user from "pusher-js/types/src/core/user";


type AuthProviderProps = {
  children: ReactNode;
};

type AuthContextData = {
  signInByEmail: (email: string, password: string) => Promise<SucessAuthDto>;
  signOutData: () => Promise<void>;
  user: ReadUsersDto | undefined;
  setUser: (value: ReadUsersDto | undefined) => void;
  isAuthenticated: boolean;
  controllerAtt: boolean;
  setControllerAtt: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  layoutData: ReadLayoutDto | null
  setLayoutData: (value: ReadLayoutDto | null) => void
  handleFetchLayoutData: () => Promise<void>
  getUserInfos: () => Promise<void>
};

export const AuthContext = createContext({} as AuthContextData);

let authChannel: BroadcastChannel;


export async function signOutData() {
  destroyCookie(undefined, "auth.token", {
    path: "/",
  });
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<ReadUsersDto>();
  const [loading, setLoading] = useState(true);

  const [layoutData, setLayoutData] = useState<ReadLayoutDto | null>(null)

  const [controllerAtt, setControllerAtt] = useState(false);
  const isAuthenticated = !!user;

  const handleFetchLayoutData = async () => {
    try {
      const response = await getLayoutByDomain();

      if (response) {
        setLayoutData(response);

        document.documentElement.style.setProperty('--primary_color', response.primaryColor || '#1a1245');
        document.documentElement.style.setProperty('--secondary_color', response.secondaryColor || '#233B5F');
        document.documentElement.style.setProperty('--theme_color', response.patternColor || '#1a1245');
      }

    } catch (error) {
      document.documentElement.style.setProperty('--primary_color', '#1a1245');
      document.documentElement.style.setProperty('--secondary_color', '#233B5F');
      document.documentElement.style.setProperty('--theme_color', '#1a1245');

    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
  }

  const getUserInfos = async () => {
    const { "auth.token": token } = parseCookies();

    if (token) {
      try {
        setLoading(true);

        const response: ReadUsersDto | undefined = await verifyToken();

        if (response) {
          setUser(response);
        };

      } catch (error) {
        signOutData();

      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 300);
    
  };

  useEffect(() => {
    authChannel = new BroadcastChannel("auth");

    authChannel.onmessage = (message) => {
      switch (message?.data) {
        case "signOut":
          signOutData();
          break;
        default:
          break;
      }
    };
  }, []);

  useEffect(() => {
    getUserInfos();
    handleFetchLayoutData();

  }, []);

  interface AuthResult {
    sucess: boolean;
    user?: ReadUsersDto;
    errorMessage?: string;
  }

  async function signInByEmail(
    email: string,
    password: string
  ): Promise<AuthResult> {
    try {
      const response: VerifyAuthDto | undefined = await login({
        email: email,
        password: password,
      });

      if (!response) {
        return { sucess: false, errorMessage: "Authentication failed." };
      }

      const { accessToken, userData } = response;

      setCookie(undefined, "auth.token", accessToken, {
        maxAge: 60 * 60 * 24 * 30,
        path: "/",
      });

      if (userData) {
        return { sucess: true, user: userData };
      };

      return { sucess: false, errorMessage: "User data not available." };

    } catch (err) {
      return { sucess: false, errorMessage: "Error during authentication." };
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signInByEmail,
        signOutData,
        isAuthenticated,
        user,
        setUser,
        controllerAtt,
        loading,
        setLoading,
        setControllerAtt,
        layoutData,
        setLayoutData,
        handleFetchLayoutData,
        getUserInfos,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
