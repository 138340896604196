import React, { createContext, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import { message, notification } from "antd";
import { parseCookies } from "nookies";
import { AuthContext } from "./AuthContext";
import { getNotificationsViewByUserUuid } from "services/notifications.service";
import { useLocation } from "react-router-dom";
import { getReportByCode, getReportByUuid } from "services/reportService";
import { getInboxMessagesByReportUuid } from "services/inboxMessages.service";
import dayjs from "dayjs";
import { verifyToken } from "services/auth";

interface NotificationContextProps {
  notifications: ReadListNotificationDto[];
  setNotifications: React.Dispatch<
    React.SetStateAction<ReadListNotificationDto[]>
  >;
  chatMessages: any[];
  setChatMessages: React.Dispatch<React.SetStateAction<any[]>>;
  setReportCodeOrUuid: React.Dispatch<React.SetStateAction<string | null>>;
}

export const NotificationContext = createContext(
  {} as NotificationContextProps
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const { "auth.token": token } = parseCookies();

  const [reportCodeOrUuid, setReportCodeOrUuid] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<ReadListNotificationDto[]>(
    []
  );
  const [chatMessages, setChatMessages] = useState<any[]>([]);

  const { user, isAuthenticated } = useContext(AuthContext);

  const notificationChannelName = "loqi";
  const chatChannelName = "loqi-chat";

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
      channelAuthorization: {
        transport: "ajax",
        endpoint: `${baseUrl}/notifications/auth`,
        headersProvider() {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
      forceTLS: true,
    });

    const handleConnected = async () => {
      try {
        const notificationChannelNamePusher = `${notificationChannelName}-${user?.uuid}`;
        const notificationChannel = pusher.subscribe(
          notificationChannelNamePusher
        );

        const chatChannelNamePusher = `${chatChannelName}-${
          reportCodeOrUuid && reportCodeOrUuid.startsWith("RP")
            ? reportCodeOrUuid
            : user?.uuid
        }`;
        const chatChannel = pusher.subscribe(chatChannelNamePusher);

        notificationChannel.bind("notification", async (data) => {
          const message = data.message;

          notification.info({
            message: "New Notification",
            description: message,
            placement: "bottomRight",
          });

          if (user && user.uuid) {
            const verifyTokenResponse = await verifyToken();

            if (!verifyTokenResponse) {
              return;
            }

            const response = await getNotificationsViewByUserUuid(user.uuid);
            setNotifications(response.data);
          }
        });

        chatChannel.bind("chat-message", async (data) => {
          if (data && data.payload) {
            setTimeout(async () => {
              const reportData =
                reportCodeOrUuid && reportCodeOrUuid.startsWith("RP")
                  ? await getReportByCode(reportCodeOrUuid)
                  : reportCodeOrUuid &&
                    (await getReportByUuid(reportCodeOrUuid));

              if (reportData && reportData.uuid) {
                const inboxMessages = await getInboxMessagesByReportUuid(
                  reportData.uuid
                );

                if (inboxMessages) {
                  setChatMessages(
                    inboxMessages.map((message) => {
                      const isFile = message.files && message.files.length > 0;
                      return {
                        userId: message.sender || message.userUuid,
                        position:
                          reportCodeOrUuid && reportCodeOrUuid.startsWith("RP")
                            ? message.sender === reportCodeOrUuid
                              ? "right"
                              : "left"
                            : message.sender === user?.uuid
                            ? "right"
                            : "left",
                        type: isFile ? "file" : "text",
                        text: isFile ? undefined : message.message,
                        data: isFile
                          ? {
                              uri: message.files,
                              name: "File",
                            }
                          : undefined,
                        date: dayjs(message.createdAt).toDate(),
                        avatar:
                          message.sender === user?.uuid
                            ? user?.profileImageUrl
                            : null,
                        alt: message.sender === user?.uuid ? user?.name : null,
                        title:
                          message.sender === user?.uuid ? user?.name : null,
                      };
                    })
                  );
                }
              }
            }, 1000);
          }
        });

        notificationChannel.bind(
          "pusher:subscription_succeeded",
          function (members: any) {}
        );

        chatChannel.bind("pusher:subscription_error", function (status: any) {
          message.error("Chat Subscription error", status);
        });

        pusher.connection.bind("error", function (err: any) {
          message.error("Pusher connection error:", err);
        });

        return () => {
          notificationChannel.unbind_all();
          pusher.unsubscribe(notificationChannelNamePusher);

          chatChannel.unbind_all();
          pusher.unsubscribe(chatChannelNamePusher);
        };
      } catch (error: any) {
        message.error("Error subscribing to the channel:", error);
      }
    };
    pusher.connection.bind("connected", handleConnected);

    return () => {
      pusher.connection.unbind("connected", handleConnected);
      pusher.disconnect();
    };
  }, [token, isAuthenticated, user?.uuid, chatMessages]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        chatMessages,
        setChatMessages,
        setReportCodeOrUuid,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
