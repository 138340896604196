import axios from "axios";
import { apiForm, apiRequest } from "./api";
import { message, notification } from "antd";

const apiUrl = process.env.REACT_APP_BASE_URL;

export const postAllTypeFile = async (
  file: File,
  reportUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("reportUuid", reportUuid);

    const response = await apiForm.post(`/storage/upload-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postAllTypeFileTemporaryToken = async (
  token: string,
  file: File,
  reportUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("reportUuid", reportUuid);

    const response = await axios.post(
      `${apiUrl}/storage/upload-file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postAllTypeFileTask = async (
  file: File,
  taskUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("taskUuid", taskUuid);

    const response = await apiForm.post(
      `/storage/upload-file-to-task`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postAllTypeFileClosedReportIssue = async (
  file: File,
  closedReportIssueUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("closedReportIssueUuid", closedReportIssueUuid);

    const response = await apiForm.post(
      `/storage/upload-file-to-closedReportIssue`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export async function getStorageByUuid(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-uuid?uuid=${uuid}`
  );
  return response;
}

export async function getStorageByReportUuid(reportUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-reportUuid?reportUuid=${reportUuid}`
  );
  return response;
}

export async function getDownloadFile(uuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/download?fileUuid=${uuid}`
  );
  return response;
}

export async function getStorageByTaskUuid(taskUuid: string) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-taskUuid?taskUuid=${taskUuid}`
  );
  return response;
}

export async function getStorageByClosedReportIssueUuid(
  closedReportIssueUuid: string
) {
  const response = await apiRequest<any>(
    "get",
    `/storage/find-by-closedReportIssueUuid?closedReportIssueUuid=${closedReportIssueUuid}`
  );
  return response;
}

export const postFileProfile = async (
  file: File,
  userUuid: string,
  companyUuid?: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("userUuid", userUuid);
    if (companyUuid) formData.append("companyUuid", companyUuid);

    const response = await apiForm.post(`/storage/profile-image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postBusinessLogo = async (
  file: File,
  layoutUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("layoutUuid", layoutUuid);

    const response = await apiForm.post(`/storage/business-logo`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postChatFile = async (
  file: File
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await apiForm.post(`/storage/chat-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });
  }
};

export const postChatFileReportToken = async (
  token: string,
  file: File
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);

    const response = await axios.post(`${apiUrl}/storage/chat-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });
  }
};

export const postBusinessReportCard = async (
  file: File,
  layoutUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("layoutUuid", layoutUuid);

    const response = await apiForm.post(
      `/storage/business-report-card`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postBusinessLoginImage = async (
  file: File,
  layoutUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("layoutUuid", layoutUuid);

    const response = await apiForm.post(
      `/storage/business-screenImage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export const postCompanyDocuments = async (
  files: File[],
  companyUuid: string
): Promise<ReadStorageDto | undefined> => {
  try {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file, file.name);
    });

    formData.append("companyUuid", companyUuid);

    const response = await apiForm.post(`/storage/document-company`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response && response.data) {
      return response.data;
    }
  } catch (error: any) {
    notification.error({
      message: error?.response?.data?.message || "Error during file upload",
      placement: "bottomRight",
    });

    return undefined;
  }
};

export async function deleteStorage(uuid: string): Promise<DeleteDto> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<DeleteDto>("delete", `/storage/soft-delete${query}`);
}

export async function getOcrFile(uuid: string): Promise<string> {
  let query = "?";
  query += uuid ? `uuid=${uuid}` : "";

  return await apiRequest<string>("get", `/storage/ocr-file${query}`);
}
