import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Img, Text } from "components";
import { Button } from "antd";
import { AuthContext } from "contexts/AuthContext";

const NotFound: React.FC = () => {
  const { layoutData } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-white-A700 flex md:flex-row flex-col-reverse   w-screen max-w-full">
        <div className="flex flex-col h-screen w-full justify-center items-center px-5">
          <div className="flex flex-col items-center gap-6">
            <img
              className="w-[200px] object-contain "
              src={layoutData ? layoutData.logo : "/images/img_logo_blue_gray_800.svg"}
              alt="Logo"
            />
            <div className="flex flex-col items-center justify-center w-full gap-2">
              <Text
                className="text-[32px] font-medium text-blue_gray-900 tracking-[-0.80px] text-center"
              >
                404 - Page Not Found
              </Text>
              <Text
                className="mt-[3px] text-blue_gray-400 text-center text-sm tracking-[0.28px]"
                size="txtNunitoRegular14"
              >
                What do you want to do?
              </Text>
            </div>
          </div>

          <div className="flex flex-col gap-5 mt-5 items-center justify-start w-full">
            <Button
              className="border-solid cursor-pointer flex items-center justify-center w-full max-w-[360px]"
              onClick={() => navigate("/whistleblower/createcomplaint")}
              style={{ background: 'var(--secondary_color)', color: 'var(--white_A700)' }}
              icon={
                <Img
                  className="h-6 mr-4"
                  src="/images/img_clock_white.svg"
                  alt="clock"
                  style={{ fill: "white" }}
                />
              }
              shape="round"
              size="large"
            >
              <div className="font-medium text-center text-sm tracking-[0.42px]">
                Create a Complaint
              </div>
            </Button>

            <Button
              className="common-pointer border bg-white-A700 cursor-pointer flex items-center justify-center w-full max-w-[360px]"
              onClick={() => navigate("/whistleblower/anonymouscomplaint")}
              icon={
                <Img
                  className="h-6 mr-4"
                  src="/images/img_message_search.svg"
                  alt="clock"
                />
              }
              shape="round"
              size="large"
            >
              <div className="!text-blue_gray-900_01 font-medium text-center text-sm tracking-[0.42px]">
                Follow Your Complaint
              </div>
            </Button>
          </div>
          <div className="absolute bottom-5">
            <Text
              className="text-[15px] text-blue_gray-800"
              size="txtNunitoMedium15"
            >
              Go to
              <a
                href="/"
                className="text-[15px] font-bold text-blue_gray-800 border-b border-transparent duration-200 hover:border-blue_gray-800"
              >
                {" "}
                Login{" "}
              </a>
              or follow your
              <a
                href="/whistleblower/anonymouscomplaint"
                className="text-[15px] text-blue_gray-800 border-b border-transparent duration-200 hover:border-blue_gray-800 font-bold"
              >
                {" "}
                Complaint
              </a>
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
